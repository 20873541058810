import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../../../settings';
import { DesktopWhatWeDoCarousel } from './DesktopWhatWeDoCarousel';
import { MobileWhatWeDoCarousel } from './MobileWhatWeDoCarousel';

export const WhatWeDoSection = () => {
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.LG });
  return (
    <section
      id='whatWeDo'
      className='tw-mx-auto tw-mt-8 tw-max-w-[1440px] tw-py-4 tw-text-brand-black lg:tw-mt-0 lg:tw-bg-transparent lg:tw-py-0'>
      <div className='tw-mx-auto tw-max-w-3xl tw-px-5'>
        <h3 className='!tw-mb-0 tw-pt-5 tw-pb-2.5 tw-text-center tw-text-lg tw-font-bold tw-text-neutrals-graphite lg:tw-pt-8 lg:tw-pb-5 lg:tw-text-2xl'>
          Custom Products & Services
        </h3>
        <p className='tw-hidden tw-text-center tw-text-sm tw-leading-5 lg:tw-block lg:tw-text-left'>
          RTIC Custom Shop puts your brand on our high-quality products. To make for a seamless experience, we
          provide end-to-end service including: design, promotional material stuffing, and drop shipping. Take
          advantage of our low-minimum order quantities on Drinkware, Coolers, and Bags. Choose from
          single-side, double-sided, and full-wrap printing.
        </p>
      </div>
      {isMobile ? <MobileWhatWeDoCarousel /> : <DesktopWhatWeDoCarousel />}
    </section>
  );
};
