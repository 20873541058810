import { useRef } from 'react';
import { useMenuHeight } from '../../../hooks';

type Section = {
  title: string;
  href: string;
};

const sections: Section[] = [
  {
    title: 'Getting Started',
    href: '#gettingStarted',
  },
  {
    title: 'What We Do',
    href: '#whatWeDo',
  },
  {
    title: 'Get In Touch With Us',
    href: '#getInTouchWithUs',
  },
];

export const CustomizationSectionNav = () => {
  const { mainMenuHeight } = useMenuHeight();
  const navRef = useRef<HTMLDivElement>(null);

  const handleSectionClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, section: Section) => {
    event.preventDefault();
    const element = document.getElementById(section.href.split('#')[1]);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
    window.history.pushState(null, '', section.href);
  };

  return (
    <div
      ref={navRef}
      style={{ top: mainMenuHeight }}
      className='tw-sticky tw-z-20 tw-hidden tw-bg-white lg:tw-block'>
      <div className='mx-auto tw-flex tw-items-center tw-justify-center tw-space-x-[30px] tw-bg-white tw-text-brand-black'>
        {sections.map((section, index) => (
          <a
            key={index}
            href={section.href}
            onClick={(event) => {
              handleSectionClick(event, section);
            }}
            className='tw-p-5 tw-text-[15px] tw-font-medium tw-text-brand-black hover:tw-text-brand-black'>
            {section.title}
          </a>
        ))}
      </div>
    </div>
  );
};
