import ResponsiveImage from '../../../components/tailwind/ResponsiveImage';

const bannerImageD = require('../images/banner/myrtic-image_d.jpg');
const bannerImageM = require('../images/banner/myrtic-image_m.jpg');
const contentSvgM = require('../images/banner/myrtic svg_m.svg');
const contentSvgD = require('../images/banner/myrtic svg_d.svg');

export const PersonalizationBanner = () => {
  return (
    <div className='tw-mx-auto tw-max-w-[1440px] tw-px-[18px] tw-pt-4 lg:tw-px-0 lg:tw-pt-10'>
      <a href='/myrtic' className='tw-relative tw-block tw-h-[528px] tw-w-full lg:tw-h-[350px]'>
        <ResponsiveImage
          image={{ mobile: bannerImageM, desktop: bannerImageD, alt: 'MyRTIC Banner' }}
          className='tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover'
        />
        <div className='tw-absolute tw-inset-0 tw-z-10'>
          <ResponsiveImage
            image={{ mobile: contentSvgM, desktop: contentSvgD, alt: 'MyRTIC Banner Content' }}
          />
        </div>
      </a>
    </div>
  );
};
