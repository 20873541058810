import CustomizationBanner from './components/CustomizationBanner';
import { CustomizationFooter } from './components/CustomizationFooter';
import { CustomizationHeader } from './components/CustomizationHeader';
import { CustomizationSectionNav } from './components/CustomizationSectionNav';
import { FAQSection } from './components/FAQSection';
import { GetInTouchWithUsForm } from './components/GetInTouchWithUsForm';
import { GettingStartedSection } from './components/GettingStartedSection';
import { OurProcessSection } from './components/OurProcessSection';
import { PersonalizationBanner } from './components/PersonalizationBanner';
import { WhatWeDoSection } from './components/WhatWeDoSection';
import { WhoWeWorkWithSection } from './components/WhoWeWorkWithSection';

export const CustomizationPage = () => {
  return (
    <>
      <CustomizationBanner allowTagOverride={false} />
      <CustomizationHeader />
      <CustomizationSectionNav />
      <GettingStartedSection />
      <div className='tw-flex tw-flex-col'>
        <div className='tw-order-2 lg:tw-order-1'>
          <WhatWeDoSection />
        </div>
        <div className='tw-order-1 lg:tw-order-2'>
          <PersonalizationBanner />
        </div>
      </div>
      <OurProcessSection />
      <WhoWeWorkWithSection />
      <GetInTouchWithUsForm />
      <FAQSection />
      <CustomizationFooter />
    </>
  );
};
