import { Breakpoints } from '@settings';
import ResponsiveImage from '../../../components/tailwind/ResponsiveImage';

const heroImageD = require('../images/hero/cs-banner_d.jpg');
const heroImageM = require('../images/hero/cs-banner_m.jpg');

export const CustomizationHeader = () => {
  return (
    <a
      href='/custom/prices'
      className='tw-relative tw-flex tw-h-[150px] tw-w-full tw-flex-col tw-items-center tw-justify-end lg:tw-h-[300px]'>
      <ResponsiveImage
        image={{
          mobile: heroImageM,
          desktop: heroImageD,
          alt: 'Custom Shop Hero Image',
        }}
        breakpoint={Breakpoints.LG}
        className='tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover'
      />
    </a>
  );
};
