import { CustomizationPage } from './features/customization/CustomizationPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import ErrorBoundary from '@components/tailwind/ErrorBoundary';
import {createRoot} from "react-dom/client";

const rootEl = document.getElementById('customizationRoot');

const queryClient = new QueryClient();

if (rootEl) {
  createRoot(rootEl).render(
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <CustomizationPage {...rootEl.dataset} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
