import ResponsiveImage from '../../../components/tailwind/ResponsiveImage';

const footerImageM = require('../images/footer/footer-image_m.jpg');
const footerImageD = require('../images/footer/footer-image_d.jpg');

export const CustomizationFooter = () => {
  return (
    <div className='tw-mb-[60px] tw-h-[450px] lg:tw-mb-[262px] lg:tw-h-[800px]'>
      <ResponsiveImage
        image={{ mobile: footerImageM, desktop: footerImageD, alt: 'Footer Image' }}
        className='tw-h-full tw-w-full tw-object-cover'
      />
    </div>
  );
};
