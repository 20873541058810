import ResponsiveImage from '../../../components/tailwind/ResponsiveImage';

const ourProcessItems = [
  {
    title: 'Shop',
    description: 'Upload your artwork, select your products, and submit your quote.',
    icon: {
      desktop: require('../images/our-process/shop icon_d.svg'),
      mobile: require('../images/our-process/shop icon_m.svg'),
    },
  },
  {
    title: 'Preview',
    description: 'Approve your design mock-up and submit your payment.',
    icon: {
      desktop: require('../images/our-process/preview icon_d.svg'),
      mobile: require('../images/our-process/preview icon_m.svg'),
    },
  },
  {
    title: 'Ship',
    description: 'Receive your shipment and enjoy your new customized RTIC products!',
    icon: {
      desktop: require('../images/our-process/ship icon_d.svg'),
      mobile: require('../images/our-process/ship icon_m.svg'),
    },
  },
];

export const OurProcessSection = () => {
  return (
    <div className='tw-py-8 tw-px-20 lg:tw-py-12'>
      <div className='tw-mx-auto tw-max-w-5xl tw-px-4 lg:tw-px-0'>
        <h3 className='!tw-mb-0 tw-pb-11 tw-text-center tw-text-lg tw-text-brand-black lg:tw-text-2xl'>
          Our Product Customization Process
        </h3>
        <div className='tw-grid tw-grid-cols-1 tw-gap-8 lg:tw-grid-cols-3  lg:tw-gap-12'>
          {ourProcessItems.map((item, index) => (
            <div key={index} className='tw-flex tw-flex-col tw-items-center tw-text-center'>
              <ResponsiveImage
                image={{ mobile: item.icon.mobile, desktop: item.icon.desktop, alt: item.title }}
              />
              <h4 className='!tw-mb-0 tw-mt-2.5 tw-text-sm tw-font-bold tw-text-brand-black lg:tw-mt-5 lg:tw-text-lg'>
                {item.title}
              </h4>
              <p className='tw-mb-0 tw-mt-1 tw-text-xs tw-leading-[17px] tw-text-brand-black lg:tw-mt-4 lg:tw-text-sm lg:tw-leading-[20px]'>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
