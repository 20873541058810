import { Button } from '@components/tailwind';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import Slider, { Settings as SliderSettings } from 'react-slick';
import ProductImageSliderBullets from '../../../components/tailwind/ProductImageSliderBullets';
import type { CustomizationTab, CustomizationTabPage } from '../customization.data';
import { tabs } from '../customization.data';
import { sliderGoTo } from '../utils';

const sliderSettings: SliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
};

const DesktopWhatWeDoTab = ({ tab }: { tab: CustomizationTab }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);

  return (
    <>
      <div className='tw-relative tw-flex-1'>
        {tab.pages[activeIndex].hasVideo ? (
          <video
            key={activeIndex}
            autoPlay
            loop
            muted
            playsInline
            poster={tab.pages[activeIndex].image.desktop}
            className='tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover'>
            <source src={tab.pages[activeIndex].video.desktop} type='video/mp4' />
          </video>
        ) : (
          <img
            src={tab.pages[activeIndex].image.desktop}
            alt={tab.title}
            className='tw-h-full tw-w-full tw-object-cover'
          />
        )}
      </div>
      <div className='tw-relative tw-aspect-[0.768]'>
        <div className='tw-absolute tw-inset-0 tw-pt-[173px] tw-pl-[60px]'>
          <Slider ref={sliderRef} {...sliderSettings} beforeChange={(_, next) => setActiveIndex(next)}>
            {tab.pages.map((page: CustomizationTabPage, i) => (
              <div key={page.title} className='tw-pr-[72px]'>
                <h3 className='tw-text-medium-grey !tw-mb-0 tw-text-2xl tw-font-bold'>{tab.title}</h3>
                <h4 className='!tw-mb-0 tw-mt-[50px] tw-text-[45px] tw-font-bold tw-leading-[56px] tw-text-brand-black'>
                  {page.title}
                </h4>
                <p className='tw-text-medium-grey !tw-mb-0 tw-mt-5 tw-text-[13px] tw-leading-[20px]'>
                  {page.description}
                </p>
                {page.buttonText && (
                  <Button href={page.href ?? '#'} size='base' variant='tertiary' className='tw-mt-14'>
                    {page.buttonText}
                  </Button>
                )}
              </div>
            ))}
          </Slider>
        </div>
        {tab.pages.length > 1 && (
          <div className='tw-absolute tw-bottom-[56px] tw-pl-[60px]'>
            <ProductImageSliderBullets
              activeSlide={activeIndex}
              count={tab.pages.length}
              onClick={sliderGoTo(sliderRef)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export const DesktopWhatWeDoCarousel = () => {
  return (
    <Tab.Group>
      <Tab.List
        as='div'
        className='tw-mt-8 tw-flex tw-w-full tw-justify-center tw-gap-[88px] tw-overflow-x-auto tw-px-4 tw-pt-5 tw-pb-4'>
        {tabs.map((tab) => (
          <Tab
            as='div'
            key={tab.tabTitle ?? tab.title}
            className={({ selected }) =>
              classNames(
                'tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-whitespace-nowrap tw-border-b-2 tw-text-[15px] tw-font-medium tw-text-neutrals-graphite tw-outline-none tw-transition-all focus:tw-outline-none',
                {
                  'tw-border-b-core-flame tw-font-extrabold tw-text-brand-black': selected,
                  'tw-border-b-transparent hover:tw-border-b-core-flame': !selected,
                }
              )
            }>
            {tab.tabTitle ?? tab.title}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {tabs.map((tab, index) => (
          <Tab.Panel as='div' key={index} className='tw-flex tw-h-[700px]'>
            <DesktopWhatWeDoTab tab={tab} />
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
