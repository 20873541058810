import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import Slider, { Settings as SliderSettings } from 'react-slick';
import ProductImageSliderBullets from '../../../components/tailwind/ProductImageSliderBullets';
import type { CustomizationTabPage } from '../customization.data';
import { tabs } from '../customization.data';
import { sliderGoTo } from '../utils';

const sliderSettings: SliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
};

export const MobileWhatWeDoCarousel = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const itemsRef = useRef<Map<number, HTMLButtonElement>>(null);
  const navRef = useRef<HTMLDivElement>(null);

  const setTabIndexFromIndex = (index: number) => {
    let total = 0;
    for (let i = 0; i < tabs.length; i++) {
      total += tabs[i].pages.length;
      if (index < total) {
        setActiveTabIndex(i);
        const map = getTabRefs();
        const node = map.get(i);
        if (navRef.current && node) {
          navRef.current.scrollTo({
            left: node.offsetLeft - (navRef.current.clientWidth + node.clientWidth) / 2,
            behavior: 'smooth',
          });
        }
        break;
      }
    }
  };
  const setIndexFromTabIndex = (tabIndex: number) => {
    let total = 0;
    for (let i = 0; i < tabIndex; i++) {
      total += tabs[i].pages.length;
    }
    setActiveIndex(total);
    sliderRef.current?.slickGoTo(total);
  };

  const getTabRefs = () => {
    if (!itemsRef.current) {
      itemsRef.current = new Map<number, HTMLButtonElement>();
    }
    return itemsRef.current;
  };
  const numSlides = useMemo(() => {
    return tabs.reduce((acc, tab) => acc + tab.pages.length, 0);
  }, [tabs]);

  return (
    <>
      <div ref={navRef} className='tw-flex tw-w-full tw-gap-5 tw-overflow-x-auto tw-p-4'>
        {tabs.map((tab, index) => (
          <button
            ref={(node) => {
              const map = getTabRefs();
              if (node) {
                map.set(index, node);
              } else {
                map.delete(index);
              }
            }}
            key={index}
            onClick={() => {
              setActiveTabIndex(index);
              setIndexFromTabIndex(index);
            }}
            className={classNames(
              'tw-flex tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-whitespace-nowrap tw-border-b-2 tw-text-[11px] tw-font-medium tw-text-neutrals-graphite tw-outline-none tw-transition-all focus:tw-outline-none',
              {
                'tw-border-b-core-flame tw-font-extrabold tw-text-brand-black': index === activeTabIndex,
                'tw-border-b-transparent': index !== activeTabIndex,
              }
            )}>
            {tab.tabTitle ?? tab.title}
          </button>
        ))}
      </div>
      <div className='tw-mx-auto tw-max-w-[400px]'>
        <Slider
          ref={sliderRef}
          {...sliderSettings}
          beforeChange={(_, next) => {
            setActiveIndex(next);
            setTabIndexFromIndex(next);
          }}>
          {tabs.flatMap((tab) =>
            tab.pages.map((page: CustomizationTabPage, i) => (
              <div key={i} className='tw-px-9 tw-py-6'>
                {page.hasVideo ? (
                  <video autoPlay loop muted playsInline className='tw-w-full' poster={page.image.mobile}>
                    <source src={page.video.mobile} type='video/mp4' />
                  </video>
                ) : (
                  <img src={page.image.mobile} alt={page.title} className='tw-w-full' />
                )}
                <h3 className='tw-mt-4 !tw-mb-0 tw-text-sm tw-font-bold tw-text-brand-black'>{tab.title}</h3>
                <h4 className='tw-mt-2.5 !tw-mb-2.5 tw-text-2xl tw-font-bold tw-text-brand-black'>
                  {page.title}
                </h4>
                <p className='tw-text-sm'>{page.description}</p>
                {page.buttonText && (
                  <a
                    className='tw-mt-5 tw-text-sm tw-leading-[20px] tw-text-brand-black tw-underline'
                    href={page.href ?? '#'}>
                    {page.buttonText}
                  </a>
                )}
              </div>
            ))
          )}
        </Slider>
      </div>
      {numSlides > 1 && (
        <ProductImageSliderBullets
          count={numSlides}
          onClick={sliderGoTo(sliderRef)}
          activeSlide={activeIndex}
        />
      )}
    </>
  );
};
