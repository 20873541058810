import { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider, { Settings as SliderSettings } from 'react-slick';
import ProductImageSliderBullets from '../../../components/tailwind/ProductImageSliderBullets';
import { Breakpoints } from '../../../settings';
import { companies } from '../customization.data';
import { sliderGoTo } from '../utils';
import ResponsiveImage from '../../../components/tailwind/ResponsiveImage';

const sliderSettings: SliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
  slidesPerRow: 5,
  autoplay: true,
  autoplaySpeed: 4000,
  draggable: true,
  swipe: true,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: Breakpoints.LG,
      settings: {
        rows: 2,
        slidesPerRow: 2,
        slidesToShow: 1,
        autoplaySpeed: 6000,
      },
    },
  ],
};

export const WhoWeWorkWithSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const isMobile = useMediaQuery({ maxWidth: Breakpoints.LG });
  const slidesPerPage = isMobile ? 4 : 5;
  const numSlides = Math.ceil(companies.length / slidesPerPage);

  return (
    <div className='tw-ml-[calc(-50vw_+_50%)] tw-w-screen tw-bg-white tw-py-9 tw-px-4 lg:tw-px-8 lg:tw-pt-[57px] lg:tw-pb-10 '>
      <div className='tw-mx-auto tw-max-w-[1440px]'>
        <h3 className='!tw-mb-0 tw-text-center tw-text-lg tw-text-neutrals-graphite lg:tw-text-2xl'>
          Who We Work With
        </h3>
        <Slider ref={sliderRef} {...sliderSettings} beforeChange={(_, next) => setActiveSlide(next)}>
          {companies.map((company, index) => (
            <div key={index}>
              <div className='tw-flex tw-w-full tw-items-center tw-justify-center tw-p-4'>
                <ResponsiveImage
                  image={{ mobile: company.image.mobile, desktop: company.image.desktop, alt: company.title }}
                />
              </div>
            </div>
          ))}
        </Slider>
        {numSlides > 1 && (
          <ProductImageSliderBullets
            count={numSlides}
            onClick={sliderGoTo(sliderRef)}
            activeSlide={activeSlide}
          />
        )}
      </div>
    </div>
  );
};
