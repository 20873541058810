import { useRef, useState } from 'react';
import Slider, { Settings as SliderSettings } from 'react-slick';
import ProductImageSliderBullets from '../../../components/tailwind/ProductImageSliderBullets';
import { Breakpoints } from '../../../settings';
import { gettingStartedCards } from '../customization.data';
import { sliderGoTo } from '../utils';
import { Button } from '@components/tailwind';

const sliderSettings: SliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  infinite: true,
  centerMode: true,
  dots: false,
  responsive: [
    {
      breakpoint: Breakpoints.XL,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: Breakpoints.LG,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: Breakpoints.SM,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '50px',
      },
    },
  ],
};

export const GettingStartedSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);
  const numSlides = gettingStartedCards.length;

  return (
    <section id='gettingStarted' className='tw-mx-auto tw-max-w-[1350px] tw-text-neutrals-medium-grey'>
      <div className='tw-mb-10 tw-mt-6 tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-3 lg:tw-mt-8 lg:tw-gap-6'>
        <h1 className='tw-mb-0 tw-text-center tw-text-[32px] tw-font-bold tw-leading-normal tw-text-brand-black lg:tw-text-[40px]'>
          RTIC Custom Shop
        </h1>
        <Button variant='primary' size='base' href='/custom/prices'>
          Start Customizing
        </Button>
      </div>

      <div className='tw-mx-auto tw-max-w-3xl tw-px-5'>
        <h3 className='!tw-mb-0 tw-pb-2.5 tw-text-center tw-text-lg tw-font-bold tw-text-brand-black lg:tw-pb-5 lg:tw-text-2xl'>
          Start Designing Your Custom Products
        </h3>
        <p className='tw-text-center tw-text-sm tw-leading-5 tw-text-brand-black lg:tw-text-left'>
          Every Custom Shop experience is uniquely tailored to fit different corporate needs. Whether you’re
          rewarding donors, thanking employees, or showing team spirit— you can choose from our wide array of
          services and products based on your needs.
        </p>
      </div>

      <div className='tw-hidden tw-grid-cols-3 tw-justify-center tw-gap-5 lg:tw-grid'>
        {gettingStartedCards.map((item, index) => (
          <Card item={item} key={index} />
        ))}
      </div>

      <div className='tw-relative tw-left-1/2 tw-w-screen -tw-translate-x-1/2 lg:tw-hidden'>
        <Slider {...sliderSettings} beforeChange={(_, next) => setActiveSlide(next)}>
          {gettingStartedCards.map((item, index) => (
            <div className='tw-p-2' key={index}>
              <Card item={item} />
            </div>
          ))}
        </Slider>
      </div>
      {numSlides > 1 && (
        <div className='lg:tw-hidden'>
          <ProductImageSliderBullets
            count={numSlides}
            onClick={sliderGoTo(sliderRef)}
            activeSlide={activeSlide}
          />
        </div>
      )}
    </section>
  );
};

const Card = ({ item }) => (
  <a
    href='/custom/prices'
    className='tw-h-full tw-px-2.5 tw-py-6 tw-no-underline md:tw-px-4 lg:tw-px-[22.5px]'>
    <div className='tw-relative tw-h-full tw-w-full tw-overflow-hidden tw-rounded-[20px] tw-bg-white tw-shadow-md tw-shadow-neutral-300'>
      <div className='tw-aspect-[0.9] tw-w-full lg:tw-aspect-[1.02]'>
        <img src={item.image} className='tw-h-full tw-w-full tw-object-cover' />
      </div>
      <div className='tw-absolute tw-inset-x-0 tw-top-0 tw-z-10 tw-hidden tw-h-1/2 tw-p-5 lg:tw-block'>
        <div className='tw-text-lg tw-font-semibold tw-leading-tight tw-text-white'>{item.title}</div>
      </div>
      <div className='tw-absolute tw-inset-x-0 tw-top-0 tw-hidden tw-h-32 tw-bg-gradient-to-b tw-from-black/60 tw-to-transparent lg:tw-block'></div>
      <div className='tw-min-h-[180px] tw-px-5 tw-pb-4 tw-pt-5 tw-text-brand-black lg:tw-min-h-0 lg:tw-px-4 lg:tw-py-6'>
        <h4 className='!tw-mb-1 tw-text-base tw-font-bold lg:tw-hidden'>{item.title}</h4>
        <p className='!tw-mb-0 tw-text-sm tw-leading-5 lg:tw-pb-0 lg:tw-text-center'>{item.description}</p>
        <div className='lg:items-center tw-mt-5 lg:tw-flex lg:tw-justify-center'>
          <Button variant='tertiary' size='sm' onClick={() => {}}>
            Start Customizing
          </Button>
        </div>
      </div>
    </div>
  </a>
);
