import { FAQs } from '../customization.data';

export const FAQSection = () => {
  return (
    <div className='tw-pb-[30px] lg:tw-pb-[95px]'>
      <h3 className='!tw-mb-0 tw-pt-8 tw-pb-4 tw-text-center tw-text-lg tw-text-brand-black lg:tw-py-12 lg:tw-text-2xl'>
        FAQ's
      </h3>
      <div className='tw-gap-x-7 tw-px-[55px] lg:tw-mx-auto lg:tw-max-w-5xl lg:tw-columns-2'>
        {FAQs.map((faq, index) => (
          <div
            key={index}
            className='tw-mb-3 tw-break-inside-avoid-column tw-text-xs tw-leading-[14px] tw-text-brand-black lg:tw-mb-2.5 lg:tw-text-[13px] lg:tw-leading-[20px]'>
            <b className='tw-mb-4 tw-text-brand-black lg:tw-text-lg'>{faq.question}</b>{' '}
            <br className='tw-hidden lg:tw-block' />
            {faq.answer}
          </div>
        ))}
      </div>
    </div>
  );
};
