export const FAQs = [
  {
    question: 'Can you ship my order to multiple addresses?',
    answer:
      'Absolutely! We offer drop shipping services for coolers and Drinkware. You can send individual items to different addresses, but we can’t ship partial drinkware cases. Connect with our team for details and pricing assistance.',
  },
  {
    question: 'Do you ship outside of the US?',
    answer: 'Not yet!',
  },
  {
    question: 'How long will it take to receive my order?',
    answer:
      'Our typical turnaround time to receive your customized products in 10-15 business days. Confirm this with your sales team representative.',
  },
  {
    question: 'Can I cancel my order once it is placed?',
    answer: 'No. All orders are final.',
  },
  {
    question: 'Can you handle rush orders?',
    answer: 'Yes, we do our best to accommodate your specific timeline. Contact us for help!',
  },
  {
    question: 'How can I see the status of my order?',
    answer: 'Please reach out to your customer service representative for order status updates.',
  },
  {
    question: 'What type of payment methods do you accept?',
    answer:
      'All orders require pre-payment and can be paid online via credit card, by ACH wire, or check. Please reach out to your customer service representative with questions.',
  },
  {
    question: 'Can you stuff our promotional materials inside the products?',
    answer: 'Yes! We can stuff printed materials, promotional materials, and more inside the products.',
  },
  {
    question: 'What type of files can I upload?',
    answer: 'Please upload vector files such as: .ai, .eps, .svg, or vectorized pdfs.',
  },
  {
    question: 'Can you help me with my design?',
    answer:
      'Our talented team of designers uses the artwork you provide to create a proof for your approval. Additional text can be added and colors can be modified in some files.',
  },
  {
    question: 'How many units do I need to order?',
    answer:
      'Drinkware must be ordered in full case quantities that vary by product. Minimum order quantities apply to all other RTIC gear and vary by product.',
  },
  {
    question: 'Can I personalize items in my order?',
    answer:
      'Yes! You can engrave names, jersey numbers, monograms, artwork and more to the items in your order through MyRTIC Group Ordering.',
  },
  {
    question: 'Does every case of Drinkware need to be the same type and/or color?',
    answer:
      'Yes, each case of Drinkware must be uniform in size and color. Mixing different products within a case is not possible. However, if you’re interested in exploring various colors or personalization options, like adding a logo on one side and a name on the other, check out MyRTIC Group Ordering.',
  },
];

export type CustomizationTab = {
  tabTitle?: string;
  title: string;
  pages: CustomizationTabPage[];
};
export type CustomizationTabPage = {
  title: string;
  description: string;
  image: {
    desktop: string;
    mobile: string;
  };
  hasVideo?: boolean;
  video?: {
    desktop: string;
    mobile: string;
  };
  buttonText?: string;
  href?: string;
};

export const tabs: CustomizationTab[] = [
  {
    tabTitle: 'Hard Coolers',
    title: 'Custom Hard Coolers',
    pages: [
      {
        title: 'Full Color',
        description:
          'Our Hard Coolers are durable, offer multi-day ice retention, and make for the perfect canvas for your logo in full color. Full Color uses a vibrant UV ink to ensure your brand is showcased in the best way possible.',
        image: {
          desktop: require('./images/what-we-do/hard-coolers_d.jpg'),
          mobile: require('./images/what-we-do/hard-coolers_m.jpg'),
        },
        buttonText: 'Shop Hard Coolers',
        href: '/custom/prices/hard-coolers',
      },
    ],
  },
  {
    tabTitle: 'Soft Coolers',
    title: 'Custom Soft Coolers',
    pages: [
      {
        title: 'Full Color',
        description:
          'Select RTIC Soft Coolers like the best-selling Soft Pack Cooler are available to customize in Full Color. Full Color uses a vibrant UV ink to ensure your brand is showcased in the best way possible.',
        image: {
          desktop: require('./images/what-we-do/soft-coolers_d.jpg'),
          mobile: require('./images/what-we-do/soft-coolers_m.jpg'),
        },
        buttonText: 'Shop Soft Coolers',
        href: '/custom/prices/soft-coolers',
      },
    ],
  },
  {
    tabTitle: 'Drinkware',
    title: 'Custom Drinkware',
    pages: [
      {
        title: 'Full Color',
        description:
          'From jugs, to mugs, to water bottles and more, our full Drinkware line is also available for Full Color printing. Full Color uses a vibrant UV ink to ensure your brand is showcased in the best way possible.',
        image: {
          desktop: require('./images/what-we-do/drinkware-full-color_d.jpg'),
          mobile: require('./images/what-we-do/drinkware_full-color_m.jpg'),
        },
        buttonText: 'Shop Drinkware',
        href: '/custom/prices/drinkware?graphicTypes=color',
      },
      {
        title: 'Engraving',
        description:
          'Laser engraving is a durable way to make your mark available on select RTIC Drinkware. The high-precision laser carefully removes the durable powder coated paint to expose the beautiful stainless steel underneath.',
        image: {
          desktop: require('./images/what-we-do/drinkware_engraving_d.jpg'),
          mobile: require('./images/what-we-do/drinkware_engraving_m.jpg'),
        },
        buttonText: 'Shop Drinkware',
        href: '/custom/prices/drinkware?graphicTypes=engraved',
      },
    ],
  },
  {
    tabTitle: 'Day Coolers',
    title: 'Custom Day Coolers',
    pages: [
      {
        title: 'Patching',
        description:
          'Add your full color logo to our everyday coolers, backpacks, and duffles. Our durable imprint process will show off your logo in all of its glory and stand the test of time.',
        image: {
          desktop: require('./images/what-we-do/day-coolers_d.jpg'),
          mobile: require('./images/what-we-do/day-cooler_m.jpg'),
        },
        buttonText: 'Shop Patching',
        href: '/custom/prices/day-coolers',
      },
    ],
  },
  {
    tabTitle: 'Bags',
    title: 'Custom Bags',
    pages: [
      {
        title: 'Patching',
        description:
          'Add your full color logo to our everyday coolers, backpacks, and duffles. Our durable imprint process will show off your logo in all of its glory and stand the test of time.',
        image: {
          desktop: require('./images/what-we-do/bags_patching_d.jpg'),
          mobile: require('./images/what-we-do/bags_patching_m.jpg'),
        },
        buttonText: 'Shop Patching',
        href: '/custom/prices/bags',
      },
      {
        title: 'Full Color',
        description:
          'Full-color is a colorful expression of your brand on your favorite RTIC products. We use a vibrant UV ink, to ensure your brand is showcased the best way possible.',
        image: {
          desktop: require('./images/what-we-do/bags_full-color_d.jpg'),
          mobile: require('./images/what-we-do/bags_full-color_m.jpg'),
        },
        buttonText: 'Shop Full Color',
        href: '/custom/prices/bags',
      },
    ],
  },
  {
    title: 'Additional Services',
    pages: [
      {
        title: 'Drop Shipping',
        description:
          'We can get your gear to your team wherever they are. Our easy drop shipping process is available for a fee, just ask your customer service representative. Single location shipping is free!',
        image: {
          desktop: require('./images/what-we-do/additional-services_drop-shipping_fallback-image_d.jpg'),
          mobile: require('./images/what-we-do/additional services_drop shipping_fall back_m.png'),
        },
        hasVideo: true,
        video: {
          desktop: require('./images/what-we-do/additional services_drop shipping_d.mp4.mp4'),
          mobile: require('./images/what-we-do/additional services_drop shipping_m.mp4'),
        },
      },
      {
        title: 'Stuffing',
        description:
          'We will insert printed materials like your business cards, flyers, brochures, and more into your Customized Products through our Stuffing services.',
        image: {
          desktop: require('./images/what-we-do/additional-services_stuffing_fallback-image_d.jpg'),
          mobile: require('./images/what-we-do/additional services_stuffing_fall back_m.jpg'),
        },
        hasVideo: true,
        video: {
          desktop: require('./images/what-we-do/additional services_stuffing_d.mp4'),
          mobile: require('./images/what-we-do/additional services_stufifng_m.mp4'),
        },
      },
    ],
  },
];

export const companies = [
  {
    title: 'NASCAR',
    image: {
      mobile: require('./images/who-we-work-with/m/nascar logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/NASCAR logo.jpg'),
    },
  },
  {
    title: 'Maui Jim',
    image: {
      mobile: require('./images/who-we-work-with/m/maui jim logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/maui jim logo.jpg'),
    },
  },
  {
    title: 'Celsius',
    image: {
      mobile: require('./images/who-we-work-with/m/Celsius logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/Celsius_Logo_full.jpg'),
    },
  },
  {
    title: 'Ariat',
    image: {
      mobile: require('./images/who-we-work-with/m/Ariat-Logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/Ariat-Logo.jpg'),
    },
  },
  {
    title: 'BuilderTrend',
    image: {
      mobile: require('./images/who-we-work-with/m/builder trend logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/builder trend logo.jpg'),
    },
  },
  {
    title: 'MLR',
    image: {
      mobile: require('./images/who-we-work-with/m/MLR logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/mlr_logo.jpg'),
    },
  },
  {
    title: 'GoRuck',
    image: {
      mobile: require('./images/who-we-work-with/m/go ruck logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/go ruck logo.jpg'),
    },
  },
  {
    title: 'Texas Rattlers',
    image: {
      mobile: require('./images/who-we-work-with/m/rattlers logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/rattlers logo.jpg'),
    },
  },
  {
    title: 'cb USA',
    image: {
      mobile: require('./images/who-we-work-with/m/cb usa logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/cb usa logo.jpg'),
    },
  },
  {
    title: 'Karbach',
    image: {
      mobile: require('./images/who-we-work-with/m/karbach logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/karbach logo.jpg'),
    },
  },
  {
    title: 'Space Cowboys',
    image: {
      mobile: require('./images/who-we-work-with/m/space cowboys logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/space cowboys logo.jpg'),
    },
  },
  {
    title: 'Metrolina Builders',
    image: {
      mobile: require('./images/who-we-work-with/m/metrolina builders logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/metrolina builders logo.jpg'),
    },
  },
  {
    title: 'Alpha Elite',
    image: {
      mobile: require('./images/who-we-work-with/m/alpha elite logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/alpha elite logo_d.png'),
    },
  },
  {
    title: 'Green Beret Foundation',
    image: {
      mobile: require('./images/who-we-work-with/m/green berets logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/green berets logo.jpg'),
    },
  },
  {
    title: 'Mitsubishi',
    image: {
      mobile: require('./images/who-we-work-with/m/mitsubishi logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/mitsubishi logo.jpg'),
    },
  },
  {
    title: 'AT&T Sports',
    image: {
      mobile: require('./images/who-we-work-with/m/AT&T sports logo_m.jpg'),
      desktop: require('./images/who-we-work-with/d/AT&T sports logo.jpg'),
    },
  },
];

export const gettingStartedCards = [
  {
    title: 'Corporate Teams & Gifting',
    description: 'Bring brand awareness, show off your company pride, or thank your employees.',
    image: require('./images/getting-started/Corporate Teams & Gifting.jpg'),
  },
  {
    title: 'Special Events & Fundraisers',
    description:
      'Make your event memorable with company swag or reward donors for increased levels of support.',
    image: require('./images/getting-started/Special-Events-&-fundraisers.jpg'),
  },
  {
    title: 'Teams & Organizations',
    description:
      'Show off your team spirit or promote your organization with customized Drinkware and Coolers.',
    image: require('./images/getting-started/Teams-&-Organizations.jpg'),
  },
];
