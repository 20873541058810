import { yupResolver } from '@hookform/resolvers/yup';
import { withGreToken } from '@utils/recaptcha';
import axios from 'axios';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { FieldValues, UseFormRegister, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import { Button, LoadingSpinner } from '../../../components/tailwind';
import { phoneRegex } from '@/constants/regex.constants';

export const GetInTouchWithUsForm = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { register, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().required('This is a required field'),
        lastName: yup.string().required('This is a required field'),
        email: yup.string().email('Please enter a valid email address').required('This is a required field'),
        phone: yup
          .string()
          .required('This is a required field')
          .matches(phoneRegex, 'Please enter a valid phone number.'),
        company: yup.string(),
        comments: yup.string(),
      })
    ),
    reValidateMode: 'onChange',
    mode: 'onBlur',
  });
  const { errors } = formState;
  const submitForm = async (data: any) => {
    const greToken = await withGreToken('contact_form');
    await axios.post('/api/contact-form', { ...data, greToken });
  };

  const submitMutation = useMutation(['contact_form'], submitForm, {
    onError: (error: any) => {
      if (error.response) {
        if (typeof error.response.data === 'object') {
          Object.keys(error.response.data).forEach((key) => {
            console.log(key, error.response.data[key]);
            // @ts-ignore - Key coming from the form error response data, so will be valid to use with setError
            setError(key, { message: error.response.data[key] });
          });
          setErrorMessage('Please fix the errors and try again');
        } else {
          setErrorMessage('There was an error while submitting the form');
        }
      }
      console.error('Failed to submit contact form');
    },
  });

  return (
    <section
      id='getInTouchWithUs'
      className='tw-flex tw-min-h-[500px] tw-items-center tw-justify-center tw-bg-neutrals-eggshell tw-pt-6 tw-pb-10 lg:tw-pt-12 lg:tw-pb-16'>
      {submitMutation.isLoading ? (
        <div className='tw-flex tw-items-center tw-justify-center'>
          <LoadingSpinner inline withContainer={false} bgVariant='none' />
        </div>
      ) : submitMutation.isSuccess ? (
        <div className='tw-mx-auto tw-px-8 tw-text-center'>
          <h2 className='tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-text-center tw-text-2xl'>
            <i className='fa fa-check-circle tw-text-core-flame'></i>
            <span>Thanks for your submission!</span>
          </h2>
          <p>Our Custom Shop Sales Team will be in touch shortly.</p>
        </div>
      ) : (
        <div className='tw-mx-auto tw-w-full tw-max-w-[740px] tw-px-5'>
          <h2 className='tw-pt-6 tw-text-center tw-text-lg tw-font-bold tw-text-brand-black lg:tw-pt-12 lg:tw-text-2xl'>
            Get in touch with us
          </h2>
          <form
            onSubmit={handleSubmit((data) => submitMutation.mutate(data))}
            className='tw-mx-auto tw-max-w-2xl tw-px-9'>
            {!!errorMessage && (
              <div className='tw-mb-2 tw-text-center tw-text-xs tw-text-red-500'>{errorMessage}</div>
            )}
            <div>
              <InputField
                type='text'
                name='firstName'
                label='First Name'
                register={register}
                required
                errorMessage={errors.firstName?.message}
              />
            </div>

            <div>
              <InputField
                type='text'
                name='lastName'
                label='Last Name'
                register={register}
                required
                errorMessage={errors.lastName?.message}
              />
            </div>

            <div>
              <InputField
                type='email'
                name='email'
                label='Email'
                register={register}
                required
                errorMessage={errors.email?.message}
              />
            </div>

            <div>
              <InputField
                type='text'
                name='phone'
                label='Phone'
                register={register}
                required
                errorMessage={errors.phone?.message}
              />
            </div>

            <div>
              <InputField
                type='text'
                name='company'
                label='Company'
                register={register}
                errorMessage={errors.company?.message}
              />
            </div>

            <div>
              <InputField
                type='textarea'
                name='comments'
                label={
                  <>
                    Tell us about your project <span className='tw-text-neutral-400'>(optional)</span>
                  </>
                }
                register={register}
                rows={5}
                errorMessage={errors.comments?.message}
              />
            </div>

            <div className='tw-mt-8 tw-flex tw-justify-center'>
              <Button size='base' disabled={!formState.isValid} type='submit' variant='primary'>
                Submit
              </Button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

type DefaultInputFieldProps = {
  name: string;
  label: string | React.ReactNode;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
  errorMessage?: string;
};
type InputFieldProps = DefaultInputFieldProps &
  (
    | {
        type: 'text' | 'email' | 'password';
      }
    | { type: 'textarea'; rows: number }
  );

const InputField = (props: InputFieldProps) => {
  const { name, register, required = false, label, errorMessage } = props;
  const { type } = props as { type: 'text' | 'email' | 'password' };
  const { type: textAreaType, rows } = props as { type: 'textarea'; rows: number };
  const isTextArea = textAreaType === 'textarea';
  const isValid = !errorMessage;
  const inputProps = useMemo(() => {
    const inputProps = {
      id: name,
      type,
      required,
      ...register(name),
      className: classNames(
        'tw-mb-1 tw-block tw-w-full tw-rounded tw-border tw-py-2 tw-px-3 tw-text-[13px] focus:tw-outline-none tw-font-normal tw-resize-none',
        {
          'tw-border-red-500 tw-bg-red-500/20 tw-text-red-500': !isValid,
          'tw-border-gray-300 tw-bg-white tw-text-brand-black': isValid,
        }
      ),
    };
    if (isTextArea) {
      return {
        ...inputProps,
        rows,
      };
    }
    return inputProps;
  }, [isTextArea, name, register, rows, type, isValid]);
  return (
    <div>
      <label
        htmlFor={name}
        className={classNames('tw-text-xs', {
          'tw-text-red-500': !isValid,
          'tw-text-brand-black': isValid,
        })}>
        {label}
        {required && '*'}
      </label>
      {isTextArea ? <textarea {...inputProps} /> : <input {...inputProps} />}
      {!isValid && (
        <div className='tw-flex tw-items-center'>
          <i className='fa fa-exclamation-circle tw-mr-1 tw-text-red-500' />
          <span className='tw-text-2xs tw-text-red-500'>{errorMessage}</span>
        </div>
      )}
    </div>
  );
};
